import { Linkedin } from 'react-bootstrap-icons';
import { useForm, ValidationError } from '@formspree/react';
import Footer from '../Components/Footer';

function ContactForm() {
    const [state, handleSubmit] = useForm("myyvndzz");
    if (state.succeeded) {
        return <p className="pt-3 pb-3 text-success fw-bold">Thank you for Contacting! Your message has been sent.</p>;
    }
    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="full-name">Full Name *</label>
            <input type="text" name="name" id="full-name" placeholder="Full Name" required />

            <label htmlFor="email-address">Email Address *</label>
            <input type="email" name="_replyto" id="email-address" placeholder="john@abc.com" required />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />

            <label htmlFor="telephone">Telephone Number *</label>
            <input type="telephone" name="telephone" id="telephone" placeholder="(555) 555-5555" required />

            <label htmlFor="message">Message</label>
            <textarea
                id="message"
                name="message"
                maxLength={500}
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
            />

            <button type="submit" disabled={state.submitting}>
                Submit
            </button>
        </form>
    );
}

function Contact() {
    return (
        <div className="container">
            <div className="contact-container">
                <div className="contact">
                    <div className="address-block">
                        <strong>USA:</strong>
                        <p>Naut, Inc.</p>
                        <p>815 Brazos St.</p>
                        <p>Ste 500</p>
                        <p>Austin, Texas, 78701</p>
                    </div>

                    <div className="address-block">
                        <strong>India:</strong>
                        <p>Naut Studio Works Private Ltd.</p>
                        <p>A 1402, Sun South Park</p>
                        <p>South Bopal</p>
                        <p>Ahmedabad, Gujarat, 380058</p>
                    </div>

                    <div className="address-block">
                        <strong>India:</strong>
                        <p>Naut Studio Works Private Ltd.</p>
                        <p>101 Shilp-1</p>
                        <p>LBS Road, Thane</p>
                        <p>Mumbai, Maharashtra, 400604</p>
                    </div>

                    <div className="social-links">
                        <a href="https://www.linkedin.com/company/nautstudio" target="__blank"><Linkedin color="#666" size={28} /></a>
                        <a href="https://www.facebook.com/nautstudio" target="__blank" style={{ paddingLeft: "3px" }}><img width={32} alt="facebook-icon" src="/images/icons/facebook-50.png" /></a>
                        <a href="https://www.instagram.com/naut_studio" target="__blank"><img width={35} alt="instagram-icon" src="/images/icons/instagram-60.png" /></a>
                        <a href="mailto:info@nautstudio.com" target="__blank"><img width={35} alt="mail-icon" src="/images/icons/mail-48.png" /></a>
                    </div>

                    <ContactForm />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;