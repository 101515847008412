import React from 'react'
import Footer from '../Components/Footer'

function Awards() {
    return (
        <div className="container mt-4">
            <div className="awards-container">
                <h5>Awards</h5>
                <div className="awards-list mb-4">
                    <div className="award">
                        <span>Tru Reality</span><br />
                        <span>Kekarav, Residential Villas Project, Runner up</span>
                    </div>
                </div>
            </div>

            <div className="press-container">
                <h5>Press</h5>
                <div className="press mb-4">
                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://www.architectmagazine.com/firms/naut-inc">
                            <span>Architect Magazine</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://architizer.com/firms/naut">
                            <span>Architizer</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://www.behance.net/nautstudio">
                            <span>Behance</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://www.dwell.com/@nautstudio">
                            <span>Dwell</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://www.builderonline.com/firms/naut-inc">
                            <span>Builder Online</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://amazingarchitecture.com/visualization/luxury-beach-resort-dubai-uae-by-naut">
                            <span>Amazing Architecture</span><br />
                        </a>
                    </div>

                    <div className="press-item">
                        <a target="_blank" rel="noreferrer" href="https://www.wettbewerbe-aktuell.de/ergebnis/luxury-beach-resort-dubai-uae-253491">
                            <span>Wettbewerbe Aktuell(WA)</span><br />
                        </a>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Awards