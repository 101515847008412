import { useState, useEffect } from 'react';
import { Linkedin, InfoCircle, ArrowLeft } from 'react-bootstrap-icons';
import Footer from '../Components/Footer';
import PeopleInfo from "../Resources/TeamContent.json";

function People() {
    const [peopleInfo, setPeopleInfo] = useState();
    const [showProfile, setShowProfile] = useState();

    useEffect(() => {
        setPeopleInfo(PeopleInfo);
    }, []);

    return (
        <div className="container">
            {!showProfile && (
                <div className="studio-container">
                    <div className="culture-block">
                        <div className="img-container">
                            <img src="/images/people/culture.jpg" alt="img" />
                        </div>
                        <div className="info-container">
                            <h5>Culture</h5>
                            <p>
                                None of the incredible things we do at NAUT would be possible without an equally amazing
                                culture, an environment in which ideas may blossom, and where you are enabled to go
                                as far as your ideas will carry you. We have dedicated ourselves to creating a culture
                                where our team brings their full selves to the table. We all play revolving project roles, and
                                we are all committed to bringing up the team as a whole. This is accomplished by developing
                                an inclusive and open culture, as well as a mindset of exploration and applied innovation.
                            </p>
                            <p>
                                A career at NAUT means immersing yourself in the realm of design and contributing to it
                                on a daily basis. It means being a part of a vibrant culture where we are all working towards
                                the same goal: "to intensify human potential and create new opportunities for individuals,
                                organizations, and communities through nobility and generosity."
                            </p>
                        </div>
                    </div>

                    <div className="core-value-cards">
                        <div className="blocks">
                            <div className="block">
                                <div className="values-img" style={{ backgroundImage: `url('/images/people/values/Customer_Success.png')`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}>
                                </div>
                                <div>
                                    <h6>Customer Triumph</h6>
                                    <p>To continually exceed customer expectations by our zeal and audacity.</p>
                                </div>
                            </div>

                            <div className="block">
                                <div className="values-img" style={{ backgroundImage: `url('/images/people/values/Integrity.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}>
                                </div>
                                <div>
                                    <h6>Integrity</h6>
                                    <p>To be moral, earnest and open in all our exchang-es.</p>
                                </div>
                            </div>

                            <div className="block">
                                <div className="values-img" style={{ backgroundImage: `url('/images/people/values/Leadership.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}>
                                </div>
                                <div>
                                    <h6>Leadership</h6>
                                    <p>To put our faith in the ability of ordinary people to be inspired and mentored to achieve remarkable results.</p>
                                </div>
                            </div>

                            <div className="block">
                                <div className="values-img" style={{ backgroundImage: `url('/images/people/values/Agility.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}>
                                </div>
                                <div>
                                    <h6>Agility</h6>
                                    <p>To work with insight, flexibility and confidence in response to challenging and changing circumstances.</p>
                                </div>
                            </div>

                            <div className="block">
                                <div className="values-img" style={{ backgroundImage: `url('/images/people/values/Excellence.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}>
                                </div>
                                <div>
                                    <h6>Excellence</h6>
                                    <p>To work diligently to make ourselves, our teams, and our services in order to be the best.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="team-block">
                        <h5>Team</h5>
                        <p>Our team works as a single, fully integrated studio of thinkers who approach every challenge with intelligence, insight and open minds. We create an interactive and collaborative
                            ecosystem that involves both face-to-face meetings, work sessions and digital technologies. Each part of this process comes together to ensure all disciplines are involved, engaged
                            and coordinated.</p>
                    </div>

                    <div className="team-members blocks">
                        {peopleInfo && peopleInfo.map((el) => {
                            return (
                                <div className="member block" key={el.id} onClick={() => {
                                    setShowProfile(el);
                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                }}>
                                    <div className="teammate-img" style={{ backgroundImage: `url('${el.img}')`, backgroundSize: 'cover', backgroundPosition: 'top' }}>
                                    </div>
                                    <div className="teammate-info">
                                        <h6>{el.name}</h6>
                                        <p>{el.full_pos} <InfoCircle /></p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>

                    <Footer />
                </div>
            )}

            {showProfile && (
                <div className="employee-cards">
                    <div className="employee-card" key={showProfile.id} id={`emp-${showProfile.id}`}>
                        <div className="employee-bio">
                            <p className="back-button" onClick={() => { setShowProfile(); }} style={{}}><ArrowLeft /></p>
                            <h5 style={{ fontSize: '16px' }}>{showProfile.full_pos}</h5>
                            <div className="info" dangerouslySetInnerHTML={{ __html: showProfile.content }} />
                        </div>
                        <div className="employee-info">
                            <img src={showProfile.color_img} alt="img" />
                            <h5 className="pt-2">{showProfile.name} {showProfile.position && (", " + showProfile.position)}</h5>
                            <div className="edu">
                                <p className="mb-1" style={{ fontSize: '16px', color: 'unset' }}>Education</p>
                                <p className="m-0 fw-light">{showProfile.college}</p>
                                <p className="m-0 fw-light">{showProfile.study}</p>
                                <p className="mt-2"><a href={`${showProfile.linkedin_url}`} target="_blank" rel="noreferrer"><Linkedin color="#666666" size={22} className="mt-1" /></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default People;