import { useState, useEffect } from 'react';
import Footer from "../Components/Footer";
import imgData from '../Resources/HomePageImages.json';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from 'react-router-dom';

function Home() {
  const [images, setImages] = useState();

  useEffect(() => {
    setImages(imgData);
  }, []);

  return (
    <div className="w-100 vh-100">
      <Carousel fade variant="dark">
        {images && images.map((el, idx) => {
          return (
            <Carousel.Item key={idx}>
              <NavLink to={`/works/${el.project_id}`}>
                <img
                  className="d-block"
                  src={el.path}
                  alt={el.caption}
                />
                <Carousel.Caption>
                  <h3>{el.caption}</h3>
                </Carousel.Caption>
              </NavLink>
            </Carousel.Item>
          );
        })
        }
      </Carousel>

      <Footer />
    </div>
  );
}

export default Home;