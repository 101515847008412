import { NavLink, useLocation } from "react-router-dom";

function Nav() {
    const location = useLocation();

    return (
        <nav>
            <NavLink activeclassname="active" to="/works">Works</NavLink>
            <NavLink activeclassname="active" to="/studio">Studio</NavLink>
            {location.pathname.includes("/studio") && (
                <div className="separated-menu">
                    <NavLink activeclassname="active" to="/studio/about">About</NavLink>
                    <NavLink activeclassname="active" to="/studio/people">People</NavLink>
                    <NavLink activeclassname="active" to="/studio/awards-press">Awards & Press</NavLink>
                    <NavLink activeclassname="active" to="/studio/contact">Contact</NavLink>
                </div>
            )}
        </nav>
    );
}

export default Nav;