import React from 'react'
import { NavLink } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <p className="pt-3 text-center"><strong>&copy; NAUT 2022 | <NavLink to="/privacy-policy">Privacy Policy</NavLink></strong></p>
        </footer>
    )
}

export default Footer;