import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="container mt-4">
            <div className="privacy-container">
                <h4>Privacy Policy and Legal Terms</h4>
                <div className="section">
                    <p>The content provided by nautstudio.com unless otherwise noted, is copyrighted. All use of nautstudio.com website is subject to the terms and conditions set forth below. All materials contained on nautstudio.com, including all text and images, are copyrighted and are protected worldwide by applicable copyright laws and treaty provisions. They may not be published, reproduced, copied, modified, uploaded, transmitted, posted, or distributed in any way, without Naut Studio Works Private Limited and Naut, Inc., prior written consent.</p>
                    <p>The materials on nautstudio.com are provided by NAUT for informational purposes only. Illustrations, photographs, computer drawings, etc. may be the property of NAUT, or illustrators and photographers hired by NAUT, its clients and/or suppliers, and are used on nautstudio.com in accordance with individual agreements. NAUT does not grant any express or implied right to you under any license, copyrights, patents, trademarks or trade secret information. Use of any image (photograph, illustration, computer rendering, etc.) from nautstudio.com requires written permission from NAUT and/or any other party, as appropriate.</p>
                    <p>NAUT has no responsibility for the protection of personal information on other sites, for the accuracy or availability of information provided on other sites, that NAUT does not control or endorse the content, products, advertising, or other materials presented on these other sites, and you release NAUT from any claims arising out of or in any way connected with any dispute you may have with another site.</p>
                    <p>Under no circumstances shall NAUT, its directors, employees and partners be responsible or liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to the nautstudio.com, the Services available on nautstudio.com or information contained within nautstudio.com, even if it has been advised of the possibility of such damages. If you violate any of these Terms of Use or if you misuse this website, you agree to defend, indemnify and hold harmless NAUT, its directors, partners, employees and licenses from any and all liability including costs, expenses and attorneys’ fees that arise out of or are related to your violation or misuse. These Terms of Use are governed by the laws of India and USA.</p>
                </div>

                <h6>Nautstudio.com / Marketing Privacy Policy</h6>
                <div className="section">
                    <p>
                        The website <strong>nautstudio.com</strong> is operated by <strong>Naut Studio Works Private Limited and Naut, Inc.</strong> (“we” or “us”) on behalf of the relevant NAUT family of companies. This means that, on behalf of the relevant NAUT family of companies, we are responsible for, and control the processing of, your personal information. This privacy policy relates to your use of our website and any online platforms such as social media, as well as electronic (email or telephone) and in-person communications.
                    </p>
                    <p>
                        We take your privacy very seriously and we ask that you read this policy carefully as it contains important information on:
                    </p>
                    <ul style={{ fontSize: "80%", color: "#666666" }}>
                        <li>The personal information we collect about you;</li>
                        <li>What we, and the NAUT family of companies, do with your information; and </li>
                        <li>Who your information might be shared with.</li>
                    </ul>
                    <p>Information which we may collect about you:<br />
                        We collect personal information about you when you contact us via our website.<br />
                        We may collect the following personal information:</p>
                    <ul style={{ fontSize: "80%", color: "#666666" }}>
                        <li>full name;</li>
                        <li>associated organization or institution;</li>
                        <li>email address;</li>
                        <li>machine identifiers (such as IP addresses);</li>
                        <li>behavioral data (see ‘Cookies and Tracking’ section below); and</li>
                        <li>Any other contact information you voluntarily provide (for example in your correspondence with us through our ‘Contact Us’ page).</li>
                    </ul>
                </div>

                <h6>Our approach to information security:</h6>
                <p>
                    The NAUT family of companies have policies and procedures in place to make sure that only authorized personnel can access the information, that information is handled and stored in a secure and sensible manner and all systems that can access the information have the necessary security measures in place. To accomplish this, all employees, contractors and sub-contractors have roles and responsibilities defined in those policies and procedures.
                </p>
                <p>
                    The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. It is possible that we might be forced to disclose your personally identifiable information in response to legal process or when we believe in good faith such disclosure is required by law, such as in response to a court order, a law enforcement agency request, in response to a subpoena, or in response to an administrative agency’s request.
                </p>
                <p>
                    Log Data is information your browser sends us whenever you visit nautstudio.com. Log Data may include information such as your computer's browser type, the browser version, and the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                </p>
                <p>
                    User-Supplied Personal Information: NAUT may request your name, email address, affiliated company and other contact information upon your request to contact individuals within NAUT or to download information from nautstudio.com. This information will be stored by NAUT and may be used by us to respond to your inquiry or to inform you of NAUT’s services or sponsored events. Users providing NAUT with this information consent to use of their personal information for marketing purposes.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;