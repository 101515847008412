import { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";
import Footer from "../Components/Footer";
import WorksInfo from "../Resources/WorksContent.json";

function Works() {
    const [allData, setAllData] = useState();
    const [info, setInfo] = useState(false);
    const [showEl, setShowEl] = useState('images');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setAllData(WorksInfo);
        if (id) {
            const project = WorksInfo.filter(pr => pr.id === parseInt(id))[0];
            setInfo(project);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id !== undefined) {
            const project = WorksInfo.filter(pr => pr.id === parseInt(id))[0];
            setShowEl('images');
            setInfo(project);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        else {
            setInfo(false);
        }
    }, [navigate, id]);

    return (
        <div className="container" style={{ padding: 0 }}>
            {allData && !info && (
                <div className="works-container">
                    <div className="projects-list">
                        {
                            allData.map((el) => {
                                return (
                                    <div className="project" key={el.id} onClick={() => {
                                        navigate(`/works/${el.id}`);
                                    }}>
                                        <div className="project-img" style={{ backgroundImage: `url('${el.images[0]}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                        </div>
                                        <div className="project-info">
                                            <h4>{el.name}</h4>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <Footer />
                    </div>
                </div>
            )}

            {info && (
                <div className="works-container" style={{ position: "relative" }}>
                    <div className="info-box mt-2 mb-4">
                        <h5 className="project-title">{info.name}</h5>
                        <div className="info-nav">
                            <p onClick={(ev) => { ev.preventDefault(); setShowEl('images'); }} style={showEl === 'images' ? { color: 'unset' } : { color: '#777777' }}>Images</p>
                            <p onClick={(ev) => { ev.preventDefault(); setShowEl('info'); }} style={showEl === 'info' ? { color: 'unset' } : { color: '#777777' }}>Information</p>
                            {info.text !== "N/A" && (<p onClick={(ev) => { ev.preventDefault(); setShowEl('text'); }} style={showEl === 'text' ? { color: 'unset' } : { color: '#777777' }}>Text</p>)}
                            <p className="back-icon" onClick={(ev) => { ev.preventDefault(); navigate(-1); }}><ArrowLeft size={19} /></p>
                        </div>
                    </div>

                    <div className={showEl === "images" ? "project-carousel show" : "project-carousel hide"}>
                        <Carousel fade variant="dark">
                            {info.images && info.images.map((el, idx) => {
                                return (
                                    <Carousel.Item key={idx}>
                                        <img
                                            className="d-block"
                                            src={el}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                );
                            })
                            }
                        </Carousel>
                    </div>

                    <div className={showEl === "info" ? "project-specs show" : "project-specs hide"} style={{ color: "unset" }}>
                        <p><strong>Name: </strong><br /> {info.project.name}</p>
                        <p><strong>Category: </strong> <br /> {info.project.category}</p>
                        <p><strong>Type: </strong> <br /> {info.project.type}</p>
                        <p><strong>Location: </strong> <br /> {info.project.location}</p>
                        <p><strong>Gross Area (SqFt): </strong> <br /> {info.project.area}</p>
                        <p><strong>Style: </strong> <br /> {info.project.style}</p>
                    </div>

                    <div className={showEl === "text" ? "project-info-text show" : "project-info-text hide"}>
                        <div dangerouslySetInnerHTML={{ __html: info.text }}></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Works;