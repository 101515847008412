import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Nav from "./Components/Nav";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Works from "./Pages/Works";
import People from "./Pages/People";
import Contact from "./Pages/Contact";
//eslint-disable-next-line
import Logo from './naut_logo.png';
import './App.css';
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Awards from "./Pages/Awards";

function App() {

  return (
    <div className="App">
      <div className="page-container" style={{ position: "relative" }}>
        <header>
          <div className="logo-container">
            <a href="/"><img src={Logo} alt="logo" width="100" /></a>
          </div>
          <Nav />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/works" element={<Works />} />
          <Route path="/works/:id" element={<Works />} />
          <Route path="/studio" element={<Navigate to="/studio/about" />} />
          <Route path="/studio/about" element={<About />} />
          <Route path="/studio/people" element={<People />} />
          <Route path="/studio/contact" element={<Contact />} />
          <Route path="/studio/awards-press" element={<Awards />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
