import Footer from "../Components/Footer";

function About() {
  return (
    <div className="container">
      <div className="about-container">
        <img className="top-img" src="/images/about/about_top.jpg" alt="img" />
        <div className="about-page-info">
          <div className="blocks">
            <div className="block">
              <h4>Next Generation</h4>
              <p>
                NAUT is a next-generation Architecture and specialized Interior Design Consultancy
                that advocates and believes in "PHILO-TECTURE," a unique blend of Philosophy and
                Architecture.
              </p>
            </div>

            <div className="block">
              <h4>Philotecture</h4>
              <p>
                Our design philosophy is distinct and stems from the guiding process of
                intentionally creating concepts while concurrently considering their objective,
                function, economics, sociocultural factors, and aesthetics.
              </p>
            </div>
          </div>

          <div className="blocks">
            <div className="block">
              <h4>Technical Excellence</h4>
              <p>
                With research and technical excellence at its core, we expertly steer our clients
                through their design journey. We do it by enabling the philosophy with best-practices
                that helps in the exemplary design solution.
              </p>
            </div>

            <div className="block">
              <h4>Honesty and Integrity</h4>
              <p>
                At Naut, honesty and integrity are our biggest virtues, and we take great
                pride in upholding them in our work ethics and culture.
              </p>
            </div>
          </div>

          <div className="blocks">
            <div className="block">
              <h4>Unprecedented Performance</h4>
              <p>
                With our presence across five countries, we empower the projects with an agile digital
                aide to deliver unprecedented performance and customer delight. Our relentless
                learning agenda drives the project's continuous improvement through building and
                transferring skills, expertise, and ideas from our innovation ecosystem.
              </p>
            </div>
            <div className="block">
              &nbsp;
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;